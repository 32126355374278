<template>
  <div class="about">
    <div class="banner"></div>
    <div class="box">
      <div
        class="title"
      >隆胜科技（Booming Technology)创立于2019年，创始技术团队拥有多年国际科技企业累积的科研成果及软硬件自主研发实力，并创立了自己的品牌-“BOT智能”，BOT智能拥有一款以软件为核心的自组网无线通讯传输智能化产品，主要应用在全屋智能改造领域，我们的专利技术在行业内是第一家通过了中国信通院的网络安全认证，在技术架构上具有比竞争对手更高的安全性和稳定性，全行业唯一承诺智能主机网关终身质保服务；并具备全国高效交付实力，承诺6人7天交付100间智能客房。现针对酒店，民宿，公寓，养老，月子中心等B端客户提供智能化改造，数字化升级服务。</div>
      <div class="conUs">
        <div class="usTit">联系方式</div>
        <img src="../../assets/about/iconOne.png" class="usImg" alt />
        <!-- <div class="usText">香港总公司：香港荔枝角永康街55号31/f 3105室</div> -->
        <div class="usText">海南分公司：海南省海口市龙华区国贸路国贸大厦B座6楼609室</div>
        <div class="usText">深圳分公司：深圳市南山区深圳湾科技生态园10栋A座4楼411</div>
        <div class="usText">西安分公司：西安市高新区沣惠南路34号新长安广场1期B座11层1102室</div>
        <div class="usText">新疆分公司：乌鲁木齐新市区西八家户路997号7栋1至2层</div>
        <img src="../../assets/about/iconTwo.png" class="usImg" alt />
        <div class="usText">info@boomingnewtech.com</div>
        <img src="../../assets/about/iconThree.png" class="usImg" alt />
        <div class="usText">400-000-7027/18566621802 (9:00-19:00)</div>
        <img src="../../assets/about/serve.png" class="serImg" alt />
        <div class="serve">客服微信</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 8em;
  background: url("../../assets/about/aboutBanner.png");
  background-size: 100% 100%;
}
.box {
  width: 12rem;
  margin: 0 auto;
  font-size: 0.24rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.4rem;
  .title {
    margin-top: 0.8rem;
    text-align: justify;
  }
  .conUs {
    margin-top: 1.38rem;
    border-top: 1px solid #333;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1.2rem;
    .usTit {
      width: 1.64rem;
      background-color: #fff;
      position: absolute;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      top: -0.18rem;
      left: 0px;
    }
    .usText {
      margin-top: 0.39rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333;
    }
    .usImg {
      margin-top: 0.39rem;
      width: 0.38rem;
      height: 0.49rem;
      &:first-of-type {
        margin-top: 0.94rem;
      }
      &:nth-of-type(2) {
        width: 0.44rem;
        height: 0.45rem;
      }
      &:nth-of-type(3) {
        width: 0.37rem;
        height: 0.49rem;
      }
    }
    .serImg {
      width: 2.22rem;
      height: 2.22rem;
      margin-top: 0.6rem;
    }
    .serve {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      margin-top: 0.29rem;
    }
  }
}
</style>